import React from 'react';
import Navbar from './global-components/navbar';
import Banner from './section-components/banner-v4';
import AboutStyleOne from './section-components/about-style-one';
import AboutStyleTwo from './section-components/about-style-two';
import GameSlider from './section-components/game-slider';
import Testimonial from './section-components/testimonial-v2';
import CTA10 from './section-components/cta-v10';
import Video from './section-components/video-v2';
import PricingLP from './section-components/pricing-lp';
import Footer from './global-components/footer-v4';
import Funfact from './section-components/funfact';
import WhyChooseUs3 from './section-components/why-choose-us-v3';
import WhyChooseUs4 from './section-components/why-choose-us-v4';
import AboutV7 from './section-components/about-v7';
import NeedHelp from './section-components/need-help';
import { Helmet } from 'react-helmet';

const Home_V1 = () => {
    return <div>
        <Helmet>
            <title>Live Show</title>
            <meta id="metaTitle" name="title" content="Live Show" />
            <meta name="description" content="Transform Your Lives with Live Show" />
            <meta name="author" content="Live Show Inc" />
            <meta property="og:site_name" content="Live Show" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.live-show.app/" />
            <meta property="og:title" content="Live Show" />
            <meta property="og:description" content="Transform Your Lives with Live Show" />
            <meta property="og:image" itemprop="image" content="%PUBLIC_URL%/assets/logo-full-light.png" />
            <meta name="msapplication-TileImage" content="%PUBLIC_URL%/assets/logo-full-light.png" />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://www.live-show.app/" />
            <meta property="twitter:title" content="Live Show" />
            <meta property="twitter:description" content="Transform Your Lives with Live Show" />
            <meta property="twitter:image" content="%PUBLIC_URL%/assets/logo-full-light.png" />
            <meta name="msapplication-TileColor" content="#00301d" />
            <meta name="theme-color" content="#00ff99" />
        </Helmet>
        <Navbar/>
        
        <Banner />
        
        <Funfact/>
        <GameSlider/>
        <Video/>
        <PricingLP/>
        


        {/*<CTA10 />*/}
        
        
        <Testimonial />
        <AboutV7/>
        <NeedHelp/>
        <WhyChooseUs3/>
        <WhyChooseUs4/>
        {/*<Video/>*/}
        
        {/*<Brand />*/}
        {/*<Team />*/}
        
        {/*<PostSlider />*/}
        <Footer />
    </div>
}

export default Home_V1

