import React, { Component } from 'react';
import { Trans } from 'react-i18next';

class TestimonialV2 extends Component {
    constructor(props) {
        super(props);
        // Initialisation de l'état pour l'élément de navbar actuellement actif
        this.state = {
            activeTab: 'tab1' // Valeur par défaut pour l'élément actif
        };
    }

    // Fonction pour changer l'onglet actif
    handleTabChange = (tab) => {
        this.setState({ activeTab: tab });
    }

    render() {
        let publicUrl = process.env.PUBLIC_URL + '/';
        let CustomClass = this.props.CustomClass ? this.props.CustomClass : '';

        return (
            <section className={"whyChooseUsV4 " + CustomClass}>
                <div className="whyChooseUsV4_titleWrapper">
                    <h3><Trans i18nKey="why_choose_us_v4_title" /></h3>
                    <p><Trans i18nKey="why_choose_us_v4_description" /></p>
                </div>
                <div className="whyChooseUsV4_navbar">
                    <span
                        className={this.state.activeTab === 'tab1' ? 'whyChooseUsV4_navbarActive' : ''}
                        onClick={() => this.handleTabChange('tab1')}
                    >
                        <Trans i18nKey="why_choose_us_v4_navbar_1" />
                    </span>
                    <span
                        className={this.state.activeTab === 'tab2' ? 'whyChooseUsV4_navbarActive' : ''}
                        onClick={() => this.handleTabChange('tab2')}
                    >
                        <Trans i18nKey="why_choose_us_v4_navbar_2" />
                    </span>
                </div>
                {this.state.activeTab === 'tab1' && (
                    <article className='whyChooseUsV4_InfoWrapper'>
                        <p><Trans i18nKey="why_choose_us_v4_navbar_1_description" /></p>
                        <img className='whyChooseUsV4_mockPhone' src={publicUrl + "assets/images/mock/phoneLiveEmoji.png"} alt="Mock Phone" />
                    </article>
                )}
                {this.state.activeTab === 'tab2' && (
                    <article className='whyChooseUsV4_InfoWrapper'>
                        <p><Trans i18nKey="why_choose_us_v4_navbar_2_description" /></p>
                        <img className='whyChooseUsV4_mockPhone' src={publicUrl + "assets/images/mock/phoneLiveEmoji.png"} alt="Mock Phone" />
                    </article>
                )}
            </section>
        );
    }
}

export default TestimonialV2;
