import React from 'react';
import Navbar from './global-components/navbar';
import Banner from './section-components/banner-liveonline';
import ListLiveOnline from './section-components/list-live-online';
import Cta from './section-components/cta-v12';
import Footer from './global-components/footer-v4';
import { Helmet } from 'react-helmet';

const PricingPage = () => {
    return <div>
        <Helmet>
            <title>Live Show - Pricing</title>
            <meta id="metaTitle" name="title" content="Get Live Show Premium" />
            <meta name="description" content="Live Show pricing to make your Stream incredible !" />
            <meta name="author" content="Live Show Inc" />

            {/* Open Graph / Facebook */}
            <meta property="og:site_name" content="Get Live Show Premium" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.live-show.app/" />
            <meta property="og:title" content="Live Show" />
            <meta property="og:description" content="Live Show pricing to make your Stream incredible !" />
            <meta property="og:image" content="%PUBLIC_URL%/assets/logo-full-light.png" />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />

            {/* Twitter */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://www.live-show.app/" />
            <meta property="twitter:title" content="Get Live Show Premium" />
            <meta property="twitter:description" content="Live Show pricing to make your Stream incredible !" />
            <meta property="twitter:image" content="%PUBLIC_URL%/assets/logo-full-light.png" />

            <meta name="msapplication-TileColor" content="#00301d" />
            <meta name="msapplication-TileImage" content="%PUBLIC_URL%/assets/logo-full-light.png" />
            <meta name="theme-color" content="#00ff99" />
        </Helmet>
        <Navbar />
        <Banner />
        <ListLiveOnline />
        <Cta />
        <Footer />
    </div>
}

export default PricingPage

