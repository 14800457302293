import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import i18n from '../../i18n';

class CtaV12 extends Component {

    render() {
      let publicUrl = process.env.PUBLIC_URL+'/'
      let imagealt = 'image'

    return <section className="cta-one cta-one__pricing-page  go-top">
        <img src={publicUrl+"assets/images/shapes/pricing-shape-2-1.png"} className="cta-one__moc-1" alt={ imagealt }/>
        <img src={publicUrl+"assets/images/shapes/pricing-shape-2-2.png"} className="cta-one__moc-2" alt={ imagealt }/>
        <div className="container">
          <div className="inner-container">
            <div className="cta-one__left">
              <h3>{i18n.t("cta12_title")}</h3>
              <p>{i18n.t("cta12_p")}</p>
            </div>{/* /.cta-one__left */}
            <div className="cta-one__right">
              <Link to="/download" className="thm-btn cta-one__btn">{i18n.t("home_banner_download_now")} <i className="fa fa-angle-double-right" /></Link>
            </div>
          </div>
        </div>
      </section>
        }
	}

export default CtaV12