import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {marked} from 'marked'
import DOMPurify from 'dompurify';
import Sidebar from './sidebar';
import articleFiles from '../../constants/articles';
import { Helmet } from 'react-helmet';

class BlogDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: '',
      title: '',
      imageUrl: '',
      imageAlt: 'Article Image',
      articles: []
    };
  }
  
  componentDidMount() {
    articleFiles.forEach(article => {
      fetch(`/articles/${article.file}`)
      .then(response => response.text())
      .then(markdown => {
        const htmlContent = DOMPurify.sanitize(marked(markdown));
        this.setState(prevState => ({
        articles: [...prevState.articles, { ...article, content: htmlContent }]
        }));
      })
      .catch(error => console.log(error));
    });

    const { slug } = this.props.match.params;
    // Remplacez cette logique par celle correspondant à la manière dont vous associez les slugs aux noms de fichiers d'articles
    const filePath = `/articles/${slug}.md`; // C'est un exemple. Adaptez-le à votre structure de fichiers.
    
    fetch(filePath)
      .then(response => response.text())
      .then(markdown => {
        const htmlContent = DOMPurify.sanitize(marked(markdown));
        // Mettre à jour le state avec le contenu HTML. Vous pouvez également extraire le titre de l'article du contenu Markdown si nécessaire.
        this.setState({ content: htmlContent });
        // Supposons que le titre et l'image soient définis dans les premières lignes du markdown ou via un autre mécanisme
        // Ces lignes suivantes sont des placeholders et doivent être adaptées à votre mise en œuvre
        this.setState({
          title: articleFiles.find(x => x.file == slug + ".md").title, // Extrait du markdown ou défini par une autre logique
          imageUrl: `/articles/images/${articleFiles.find(x => x.file == slug + ".md").image}`, // Idem
        });
      });
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + '/';
	  const recentArticles = this.state.articles.slice(0, 3);
    return (
      <section className="blog-list blog-details">
        <Helmet>
            <title>Live Show - {this.state.title}</title>
            <meta id="metaTitle" name="title" content={`Live Show - ${this.state.title}`} />
            <meta name="description" content="Download Live Show for more interactions with your Stream" />
            <meta name="author" content="Live Show Inc" />

            {/* Open Graph / Facebook */}
            <meta property="og:site_name" content={`Live Show - ${this.state.title}`} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.live-show.app/news" />
            <meta property="og:title" content={`Live Show - ${this.state.title}`} />
            <meta property="og:description" content="Discover Live Show news and upcoming features" />
            <meta property="og:image" content="%PUBLIC_URL%/assets/logo-full-light.png" />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />

            {/* Twitter */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://www.live-show.app/news" />
            <meta property="twitter:title" content={`Live Show - ${this.state.title}`} />
            <meta property="twitter:description" content="Discover Live Show news and upcoming features" />
            <meta property="twitter:image" content="%PUBLIC_URL%/assets/logo-full-light.png" />

            <meta name="msapplication-TileColor" content="#00301d" />
            <meta name="msapplication-TileImage" content="%PUBLIC_URL%/assets/logo-full-light.png" />
            <meta name="theme-color" content="#00ff99" />
        </Helmet>
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="blog-one__single">
                <div className="blog-one__image">
                  {/* Affichage de l'image de l'article */}
                  <img src={this.state.imageUrl || `${publicUrl}assets/images/blog/blog-s-1-1.jpg`} alt={this.state.imageAlt} />
                </div>
                <div className="blog-one__content">
                  {/* Affichage du titre de l'article */}
                  <h3>{this.state.title}</h3>
                  {/* Affichage du contenu de l'article */}
                  <div dangerouslySetInnerHTML={{__html: this.state.content}}></div>
                  <br />
                </div>
              </div>
              {/* Vous pouvez ajouter ici d'autres composants comme la navigation entre les articles ou les tags */}
            </div>
            <Sidebar recentArticles={recentArticles} />
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(BlogDetails);
