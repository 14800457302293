import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import i18n from '../../i18n';
import axios from "axios";

class CtaV10 extends Component {

    state = {
      numberOfUsers: null,
      error: null
      };

    componentDidMount()
    {
      // Effectuer la requête GET lorsque le composant est monté
      axios.get("https://api.live-show.app/user/get_number_of_users")
      .then(response => {
        // Mettre à jour l'état avec le nombre d'utilisateurs
        this.setState({ numberOfUsers: response.data });
      })
      .catch(error => {
        // Gérer les erreurs
        this.setState({ error: error.message });
      });
    }


    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        const { numberOfUsers, error } = this.state;

    return <section className="cta-eleven  go-top">
              <img src={publicUrl+"assets/images/shapes/cta-shape-2-1.png"} className="cta-eleven__moc-1" alt="cta image" />
              <img src={publicUrl+"assets/images/shapes/cta-shape-2-2.png"} className="cta-eleven__moc-2" alt="cta image" />
              <img src={publicUrl+"assets/images/shapes/cta-shape-2-3.png"} className="cta-eleven__moc-3" alt="cta image" />
              <div className="container">
                <h3>{i18n.t("cta10_title")}</h3>
                <p>{i18n.t("cta10_p")}</p>
                <span className='download__membership'>
                  <Link to="/download" className="thm-btn"><span className='icon-in-btn'><i className="fa fa-download " /></span> {i18n.t("download")} </Link>
                  <h5 className='membership_number'>+<span className="counter">{numberOfUsers}</span> <br/> {i18n.t("users")}</h5>
                </span>
                
                <p className='HandWrite'>{i18n.t("free_for_life")}</p>
                {/* /.thm-btn cta-eleven__btn */}
              </div>{/* /.container */}
            </section>


        }
	}

export default CtaV10