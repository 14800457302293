import React, { Component } from 'react';
import i18n from '../../i18n.js'
import { Trans } from 'react-i18next';

class TestimonialV2 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'
        let CustomClass = this.props.CustomClass ? this.props.CustomClass : ''

    return  <section className={"aboutV7 "+CustomClass}>
					<div className="block-title-three">
						<h3><Trans i18nKey="about_v7_title" /></h3>
						<div className="block-title__line" />
					</div>
					<article className="aboutV7_InfoWrapper">

						<span className='aboutV7_InfoItem'>
							<img src={publicUrl+"assets/images/shapes/service-i-1.svg"}/>
							<h4><Trans i18nKey="about_v7_subtitle_1" /></h4>
							<p><Trans i18nKey="about_v7_decription_1" /></p>
						</span>
						<span className='aboutV7_InfoItem'>
							<img src={publicUrl+"assets/images/shapes/service-i-2.svg"}/>
							<h4><Trans i18nKey="about_v7_subtitle_2" /></h4>
							<p><Trans i18nKey="about_v7_decription_2" /></p>
						</span>
						<span className='aboutV7_InfoItem'>
							<img src={publicUrl+"assets/images/shapes/service-i-3.svg"}/>
							<h4><Trans i18nKey="about_v7_subtitle_3" /></h4>
							<p><Trans i18nKey="about_v7_decription_3" /></p>
						</span>
					
					</article>

			</section>

			 
        }
}

export default TestimonialV2