import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, HashRouter, Route, Switch } from "react-router-dom";
import HomeV1 from './components/home-v1';
import About from './components/about';
import Pricing from './components/pricing';
import Faq from './components/faq';
import Download from './components/download';
import Term from './components/terms_of_use_page'
import Policy from './components/privacy_policy_page'
import Refund from './components/refund_policy_page'
import Error from './components/404_error_page'
import BlogList from './components/blog-list';
import BlogDetails from './components/blog-details';
import RegisterPage from './components/register';
import RegisterSuccessPage from './components/register-success';
import LiveOnline from './components/live-online';

class Root extends Component {

    render() {
        return(
                <HashRouter basename="/">
	                <div>
	                <Switch>
	                    <Route exact path="/" component={HomeV1} />
	                    <Route path="/about" component={About} />
						<Route path="/download" component={Download} />
	                    <Route path="/pricing" component={Pricing} />
	                    <Route path="/faq" component={Faq} />
	                    <Route path="/terms_of_use" component={Term} />
	                    <Route path="/privacy_policy" component={Policy} />
	                    <Route path="/refund_policy" component={Refund} />
						<Route path="/news" exact component={BlogList} />
     				    <Route path="/news/:slug" component={BlogDetails} />
     				    <Route path="/register" component={RegisterPage} />
     				    <Route path="/register_success" component={RegisterSuccessPage} />
     				    <Route path="/live_online" component={LiveOnline} />
						<Route path="*" component={Error}/>
						
	                    {/*<Route path="/contact" component={Contact} />*/}
	                </Switch>
	                </div>
                </HashRouter>
        )
    }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('liveshow'));
