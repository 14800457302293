import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import i18n from '../../i18n';
import { Trans } from 'react-i18next';

class BannerV4 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <section className="banner-four  go-top">
			<video className="banner-one__moc" controls autoPlay muted loop>
				<source src={publicUrl+"assets/images/resources/banner-1-1.mp4"} type="video/mp4" />
			</video>
			<img src={publicUrl+"assets/images/logo_icon_white.svg"} className="banner-one__moc banner-one__moc-branding"/>
			<div className="container-fluid">
				<div className="row">
					<div className="col-lg-12">
						<div className="banner-four__content">
						<h4>{i18n.t('success_your_lives')}</h4>
						<h3>{i18n.t('home_banner_title_1')} <br />
							<span>{i18n.t('home_banner_title_2')}</span></h3>
						<p> <Trans i18nKey="home_banner_p" /> </p>
						<div className='banner_info_flex'>
							<div className='banner_info_flex_col'>
								<span><i class="fas fa-check-circle"></i> <Trans i18nKey="home_banner_info_list_1" /> </span>
								<span><i class="fas fa-check-circle"></i> <Trans i18nKey="home_banner_info_list_2" /></span>
							</div>
							<div className='banner_info_flex_col'>
								<span><i class="fas fa-check-circle"></i> <Trans i18nKey="home_banner_info_list_3" /></span>
								<span><i class="fas fa-check-circle"></i> <Trans i18nKey="home_banner_info_list_4" /></span>
							</div>
						</div>
						<span className='banner-four__btnWrapper'>
							<Link to="/register" className="thm-btn banner-four__btn">{i18n.t('start_now')} <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn banner-four__btn */}
							<h3><Trans i18nKey="free_for_life" /></h3>
						</span>
						</div>{/* /.banner-four__content */}
					</div>{/* /.col-lg-12 */}
				</div>{/* /.row */}
			</div>{/* /.container */}
			<svg className='TextWavePathBanner' width="100%" height="141" viewBox="0 0 1440 141" fill="none" xmlns="http://www.w3.org/2000/svg">
				
				<path id='pathTextBanner' d="M0 6L60 32.6449C120 58.8906 240 112.779 360 133.736C480 154.692 600 144.713 720 117.769C840 90.8246 960 48.9113 1080 32.6449C1200 16.9773 1320 26.9567 1380 32.6449L1440 37.934" stroke="none"/>
				<path d="M60 26.7L0 0V189H1440V32L1380 26.7C1320 21 1200 11 1080 26.7C960 43 840 85 720 112C600 139 480 149 360 128C240 107 120 53 60 26.7Z" fill="white"/>
				<path d="M60 26.7L0 0V28L60 54.7C120 81 240 135 360 156C479 176 600 167 720 140C840 113 960 71 1080 54.7C1200 39 1320 49 1380 54.7L1440 60V32L1380 26.7C1320 21 1200 11 1080 26.7C960 43 840 85 720 112C600 139 480 149 360 128C240 107 120 53 60 26.7Z" fill="#FF57A4" fill-opacity="0.2"/>
				<text>
					<textPath href='#pathTextBanner' text-anchor="middle" startOffset="50%">
						💎 <Trans i18nKey="wave_bottom_1" /> 🎁 <Trans i18nKey="wave_bottom_2" /> 🤩
					</textPath>
				</text>
			</svg>
			<img src={publicUrl+"assets/images/banner/bannerRuban.svg"} alt={ imagealt } className="banner_bottom" />
		</section>

        }
}

export default BannerV4