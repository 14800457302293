import React, { Component } from 'react';
import i18n from '../../i18n.js'

class WhyChooseUs extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return  <section className="service-one go-top">
          <div className="container">
            <div className="block-title text-center">
              <p><span>{i18n.t('why_choose_us')}</span></p>
              <h3><i class="fas fa-gem"></i> <br/>
                {i18n.t("success_your_lives")} <br />
                {i18n.t("home_banner_title_2")} </h3>
              <div className="block-title__line" />{/* /.block-title__line */}
            </div>{/* /.block-title */}
            <div className="row high-gutter  go-top">
              <div className="col-lg-4">
                <div className="service-one__single">
                  <div className="service-one__top">
                    <div className="service-one__icon">
                      <img src={publicUrl+"assets/images/shapes/service-i-1.png"} alt={ imagealt } />
                    </div>{/* /.service-one__icon */}
                    <div className="service-one__top-content">
                      {/* <h3><Link to="/service-details">Monitoring</Link></h3> */}
                      <h3>{i18n.t('game_changer')}</h3>
                      <p>{i18n.t('game_changer_sentence')}</p>
                    </div>{/* /.service-one__top-content */}
                  </div>{/* /.service-one__top */}
                  <p>{i18n.t('game_changer_p')}</p>
                </div>{/* /.service-one__single */}
              </div>{/* /.col-lg-4 */}
              <div className="col-lg-4">
                <div className="service-one__single">
                  <div className="service-one__top">
                    <div className="service-one__icon">
                      <img src={publicUrl+"assets/images/shapes/service-i-2.png"} alt={ imagealt } />
                    </div>
                    <div className="service-one__top-content">
                      {/* <h3><Link to="/service-details">Multiple Integrations</Link></h3> */}
                      <h3>{i18n.t("next_level")}</h3>
                      <p>{i18n.t("next_level_sentence")}</p>
                    </div>{/* /.service-one__top-content */}
                  </div>{/* /.service-one__top */}
                  <p>{i18n.t("next_level_p")}</p>
                </div>{/* /.service-one__single */}
              </div>{/* /.col-lg-4 */}
              <div className="col-lg-4">
                <div className="service-one__single">
                  <div className="service-one__top">
                    <div className="service-one__icon">
                      <img src={publicUrl+"assets/images/shapes/service-i-3.png"} alt={ imagealt } />
                    </div>{/* /.service-one__icon */}
                    <div className="service-one__top-content">
                      {/* <h3><Link to="/service-details">Fast Run</Link></h3> */}
                      <h3>{i18n.t("crazy_lives")}</h3>
                      <p>{i18n.t("crazy_lives_sentence")}</p>
                    </div>{/* /.service-one__top-content */}
                  </div>{/* /.service-one__top */}
                  <p>{i18n.t("crazy_lives_p")}</p>
                </div>{/* /.service-one__single */}
              </div>{/* /.col-lg-4 */}
            </div>{/* /.row */}
          </div>{/* /.container */}
        </section>

        }
}

export default WhyChooseUs