import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import i18n from '../../i18n';
import axios from "axios";

class PricingV3 extends Component {

	state = {
		numberOfUsers: null,
		error: null
	  };

	componentDidMount()
	{
		// Effectuer la requête GET lorsque le composant est monté
		axios.get("https://api.live-show.app/user/get_premium_number_of_users")
		.then(response => {
			// Mettre à jour l'état avec le nombre d'utilisateurs
			this.setState({ numberOfUsers: response.data });
		})
		.catch(error => {
			// Gérer les erreurs
			this.setState({ error: error.message });
		});
	}

  render() {

      let publicUrl = process.env.PUBLIC_URL+'/'
      let imagealt = 'image'

      const { numberOfUsers, error } = this.state;

    return  <section className="pricing-two pricing-two-2">
        <div className="container">
          <div className="list-live-online row flex__center">
            <span className='card_live-online'>
              <img src={publicUrl+"assets/images/customers/sirius_le_brave.png"} alt={ imagealt } className='profile-picture_live-online' />
              <h4>SiruisLeBrave</h4>
              <h5>Minecraft</h5>
              <h5>Viewers : <span>15</span></h5>
            </span>
            <span className='card_live-online'>
              <img src={publicUrl+"assets/images/customers/sirius_le_brave.png"} alt={ imagealt } className='profile-picture_live-online' />
              <h4>SiruisLeBrave2222222</h4>
              <h5>Minecraft</h5>
              <h5>Viewers : <span>15</span></h5>
            </span>
            <span className='card_live-online'>
              <img src={publicUrl+"assets/images/customers/sirius_le_brave.png"} alt={ imagealt } className='profile-picture_live-online' />
              <h4>SiruisLeBrave2222222</h4>
              <h5>Minecraft</h5>
              <h5>Viewers : <span>15</span></h5>
            </span>
            <span className='card_live-online'>
              <img src={publicUrl+"assets/images/customers/sirius_le_brave.png"} alt={ imagealt } className='profile-picture_live-online' />
              <h4>SiruisLeBrave2222222</h4>
              <h5>Minecraft</h5>
              <h5>Viewers : <span>15</span></h5>
            </span>
            <span className='card_live-online'>
              <img src={publicUrl+"assets/images/customers/sirius_le_brave.png"} alt={ imagealt } className='profile-picture_live-online' />
              <h4>SiruisLeBrave2222222</h4>
              <h5>Minecraft</h5>
              <h5>Viewers : <span>15</span></h5>
            </span>
            <span className='card_live-online'>
              <img src={publicUrl+"assets/images/customers/sirius_le_brave.png"} alt={ imagealt } className='profile-picture_live-online' />
              <h4>SiruisLeBrave2222222</h4>
              <h5>Minecraft</h5>
              <h5>Viewers : <span>15</span></h5>
            </span>
            <span className='card_live-online'>
              <img src={publicUrl+"assets/images/customers/sirius_le_brave.png"} alt={ imagealt } className='profile-picture_live-online' />
              <h4>SiruisLeBrave2222222</h4>
              <h5>Minecraft</h5>
              <h5>Viewers : <span>15</span></h5>
            </span>
            <span className='card_live-online'>
              <img src={publicUrl+"assets/images/customers/sirius_le_brave.png"} alt={ imagealt } className='profile-picture_live-online' />
              <h4>SiruisLeBrave2222222</h4>
              <h5>Minecraft</h5>
              <h5>Viewers : <span>15</span></h5>
            </span>
            <span className='card_live-online'>
              <img src={publicUrl+"assets/images/customers/sirius_le_brave.png"} alt={ imagealt } className='profile-picture_live-online' />
              <h4>SiruisLeBrave2222222</h4>
              <h5>Minecraft</h5>
              <h5>Viewers : <span>15</span></h5>
            </span>
            <span className='card_live-online'>
              <img src={publicUrl+"assets/images/customers/sirius_le_brave.png"} alt={ imagealt } className='profile-picture_live-online' />
              <h4>SiruisLeBrave2222222</h4>
              <h5>Minecraft</h5>
              <h5>Viewers : <span>15</span></h5>
            </span>
            <span className='card_live-online'>
              <img src={publicUrl+"assets/images/customers/sirius_le_brave.png"} alt={ imagealt } className='profile-picture_live-online' />
              <h4>SiruisLeBrave2222222</h4>
              <h5>Minecraft</h5>
              <h5>Viewers : <span>15</span></h5>
            </span>
            <span className='card_live-online'>
              <img src={publicUrl+"assets/images/customers/sirius_le_brave.png"} alt={ imagealt } className='profile-picture_live-online' />
              <h4>SiruisLeBrave2222222</h4>
              <h5>Minecraft</h5>
              <h5>Viewers : <span>15</span></h5>
            </span>
            <span className='card_live-online'>
              <img src={publicUrl+"assets/images/customers/sirius_le_brave.png"} alt={ imagealt } className='profile-picture_live-online' />
              <h4>SiruisLeBrave2222222</h4>
              <h5>Minecraft</h5>
              <h5>Viewers : <span>15</span></h5>
            </span>
          </div>{/* /.list-live-online */}
        </div>{/* /.container */}
      </section>


        }
}

export default PricingV3