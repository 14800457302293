import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './sidebar';
import {marked} from 'marked'
import DOMPurify from 'dompurify';
import articleFiles from '../../constants/articles';
import { Helmet } from 'react-helmet';

class BlogList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: []
    };
  }

  componentDidMount() {
	articleFiles.forEach(article => {
	  fetch(`/articles/${article.file}`)
		.then(response => response.text())
		.then(markdown => {
		  const htmlContent = DOMPurify.sanitize(marked(markdown));
		  this.setState(prevState => ({
			articles: [...prevState.articles, { ...article, content: htmlContent }]
		  }));
		})
		.catch(error => console.log(error));
	});
  }
  
  renderArticles() {
	return this.state.articles.map((article, index) => {
	  const slug = article.file.replace('.md', ''); // Identifiant unique pour le lien
	  const excerpt = article.content.substring(0, 150) + "..."; // Extrait de l'article
  
	  return (
		<div key={index} className="blog-one__single">
		  <div className="blog-one__image">
			{/* Ajoute l'image de l'article ici */}
			<img src={`/articles/images/${article.image}`} />
		  </div>
		  <div className="blog-one__content">
			<h3><Link to={`/news/${slug}`}>{article.title}</Link></h3>
			
			<div dangerouslySetInnerHTML={{__html: this.state.content}}></div>
			<Link to={`/news/${slug}`} className="thm-btn btn-readmore">Read More <i className="fa fa-angle-double-right" /></Link>
		  </div>
		</div>
	  );
	});
  }
  
  

  render() {
    let publicUrl = process.env.PUBLIC_URL+'/';
    let imagealt = 'image';
	const recentArticles = this.state.articles.slice(0, 3);
    return (
      <section className="blog-list go-top">
		<Helmet>
            <title>Live Show - News</title>
            <meta id="metaTitle" name="title" content={`Live Show - News`} />
            <meta name="description" content="Download Live Show for more interactions with your Stream" />
            <meta name="author" content="Live Show Inc" />

            {/* Open Graph / Facebook */}
            <meta property="og:site_name" content={`Live Show - News`} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.live-show.app/news" />
            <meta property="og:title" content={`Live Show - News`} />
            <meta property="og:description" content="Discover Live Show news and upcoming features" />
            <meta property="og:image" content="%PUBLIC_URL%/assets/logo-full-light.png" />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />

            {/* Twitter */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://www.live-show.app/news" />
            <meta property="twitter:title" content={`Live Show - News`} />
            <meta property="twitter:description" content="Discover Live Show news and upcoming features" />
            <meta property="twitter:image" content="%PUBLIC_URL%/assets/logo-full-light.png" />

            <meta name="msapplication-TileColor" content="#00301d" />
            <meta name="msapplication-TileImage" content="%PUBLIC_URL%/assets/logo-full-light.png" />
            <meta name="theme-color" content="#00ff99" />
        </Helmet>
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              {this.renderArticles()}
              {/* Ici vous pouvez ajouter la pagination ou d'autres éléments */}
            </div>
            <Sidebar recentArticles={recentArticles} />
          </div>
        </div>
      </section>
    );
  }
}

export default BlogList;
