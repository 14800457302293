import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
class BlogDetails extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'

    return (
     <section className="blog-list blog-details">
		<Helmet>
            <title>Live Show - Privacy Policy</title>
            <meta id="metaTitle" name="title" content="Live Show - Privacy Policy" />
            <meta name="description" content="Download Live Show for more interactions with your Stream" />
            <meta name="author" content="Live Show Inc" />

            {/* Open Graph / Facebook */}
            <meta property="og:site_name" content="Live Show - Privacy Policy" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.live-show.app/" />
            <meta property="og:title" content="Live Show" />
            <meta property="og:description" content="Download Live Show for more interactions with your Stream" />
            <meta property="og:image" content="%PUBLIC_URL%/assets/logo-full-light.png" />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />

            {/* Twitter */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://www.live-show.app/" />
            <meta property="twitter:title" content="Live Show - Privacy Policy" />
            <meta property="twitter:description" content="Download Live Show for more interactions with your Stream" />
            <meta property="twitter:image" content="%PUBLIC_URL%/assets/logo-full-light.png" />

            <meta name="msapplication-TileColor" content="#00301d" />
            <meta name="msapplication-TileImage" content="%PUBLIC_URL%/assets/logo-full-light.png" />
            <meta name="theme-color" content="#00ff99" />
        </Helmet>
		  <div className="container">
		    <div className="row">
		      <div className="col-lg-15">
		        <div className="blog-one__single">
		          <div className="blog-one__content">
		            <div className="blog-one__top">
		              <div className="blog-one__author">
		                <p style={{marginLeft: -0.2 + 'px'}}>Last Update : 9 Feb. 2024</p>
		              </div>{/* /.blog-one__author */}
		            </div>{/* /.blog-one__top */}
		            <h3>Here is our Privacy Policy</h3>
		            <p>We attach great importance to the confidentiality of your personal information. This privacy policy details how we collect, use and protect your data when you use our services</p>
		            <br />
					<h3>Collection and use of information</h3>
		            <p>We only collect information necessary to provide our services and improve your user experience. The information we collect may include, but is not limited to, your name, email address and other personal information you choose to provide to us.</p>
		            <br />
					<h3>We use your information to:</h3>
                    <ul>
                        <li>Provide the services you request.</li>
                        <li>Communicate with you regarding your use of our services.</li>
                        <li>Personalize and improve our services.</li>
                        <li>Notify you of updates, special offers and other information that may be of interest to you.</li>
                    </ul>
                    <p>We do not sell, trade, or rent your personal information to third parties for commercial purposes without your explicit consent.</p>
		          	<br/>
					<h3>Information Protection</h3>
					<p>We implement technical, administrative and physical security measures to protect your information against unauthorized access, disclosure, alteration or destruction.</p>
				  	<br/>
					<h3>Links to third party sites</h3>
					<p>Our Services may contain links to third-party websites. We are not responsible for the content or privacy practices of these sites. We encourage you to review the privacy policies of these third parties before providing your personal information.</p>
				  	<br/>
					<h3>Privacy Policy Changes</h3>
					<p>We reserve the right to modify this privacy policy at any time. Any changes will take effect upon publication on our website. We encourage you to check this page regularly for any updates.</p>
					<br/>
					<h3>Contact Us</h3>
					<p>If you have any questions regarding our privacy policy, please contact us at the following address: <b>contact@live-show.app</b></p>

				  </div>{/* /.blog-one__content */}
		          <br />
		        </div>{/* /.blog-one__single */}
		      </div>{/* /.col-lg-8 */}
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>

    )
  }
}

export default BlogDetails;
