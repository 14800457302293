import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';


class Sidebar extends Component {

  handleLinkClick = (path) => {
    // Empêche le comportement par défaut
    this.props.history.push(path); // Utilise l'API history pour naviguer
    window.location.reload(); // Force le rechargement de la page
  };

  render() {
    let publicUrl = process.env.PUBLIC_URL+'/';
    let imagealt = 'image';
    
    return (
      <div className="col-lg-4 go-top">
        <div className="sidebar">
          <div className="sidebar__single sidebar__post">
            <h3 className="sidebar__title">Recent News</h3>
            {this.props.recentArticles.map((article, index) => (
              <div key={index} className="sidebar__post-single">
                <div className="sidebar__post-image">
                  <img src={`/articles/images/${article.image}` || `${publicUrl}assets/images/blog/blog-lp-1-1.jpg`} alt={imagealt} />
                </div>
                <div className="sidebar__post-content">
                  <h3>
                    <a href={`/news/${article.file.replace(".md", "")}`} onClick={(e) => { e.preventDefault(); this.handleLinkClick(`/news/${article.file.replace(".md", "")}`); }}>{article.title}</a>
                  </h3>
                  <span>{article.date}</span>
                </div>
              </div>
            ))}
          </div>
          {/* Contact Us Side Bar
          <div className="sidebar__single sidebar__cta" style={{backgroundImage: 'url(assets/images/blog/blog-s-cta-1.jpg)'}}>
            <h3>Work <br /> Together</h3>
            <p>Bur wemust ipsum dolor sit amet <br /> consectetur adipisicing elit sed eiusmod <br /> tempor
              incididunt ut labore</p>
            <a href="#" className="thm-btn sidebar__cta-btn">Contact Now <i className="fa fa-angle-double-right" /></a>
          </div>
           */}
        </div>
      </div>
    );
  }
}

export default withRouter(Sidebar); // Enveloppe le composant avec withRouter