// CenteredImageSlider.js
import React from "react";
import i18n from '../../i18n.js'
import { Trans } from 'react-i18next';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

let publicUrl = process.env.PUBLIC_URL+'/'

const images = [
    publicUrl+"assets/images/game/minecraft.png",
    publicUrl+"assets/images/game/foodAvalanche.png",
    publicUrl+"assets/images/game/left4dead2.png",
    publicUrl+"assets/images/game/gta5.png",
];

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block",  background: "#ff57a4", transform: "scale(2) translateX(-50%)",borderRadius: "100%", paddingTop: "1px"}}
            onClick={onClick}
        />

    );
}

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block",  background: "#ff57a4", transform: "scale(2) translateX(50%)",borderRadius: "100%", paddingTop: "1px"}}
            onClick={onClick}
        />
    );
}

function CenteredImageSlider() {
    const settings = {
        centerMode: true,
        centerPadding: "50px",
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: <SamplePrevArrow />,
        nextArrow: <SampleNextArrow />,
        autoplay: true,
        autoplaySpeed: 3000,
        cssEase: "ease-in-out",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    prevArrow: <SamplePrevArrow />,
                    nextArrow: <SampleNextArrow />,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    prevArrow: <SamplePrevArrow />,
                    nextArrow: <SampleNextArrow />,
                },
            },
        ],
    };

    return (
        <article className="slider-container block-title">
            <h3> <Trans i18nKey="title_game_slider" /> </h3>
            <Slider {...settings}>
                {images.map((src, index) => (
                    <div key={index} className="slider-item">
                        <img src={src} alt={`Slide ${index}`} className="slider-image" />
                    </div>
                ))}
            </Slider>
            <article className="slider-info">
                <i class="fas fa-puzzle-piece"></i>
                
                <p>
                    <h1>{i18n.t("what_interactive_game")}</h1>
                    {i18n.t("what_interactive_game_desc")}
                </p>
                <img src={publicUrl+"assets/images/logo_icon_white.svg"}/>
            </article>
        </article>
    );
}

export default CenteredImageSlider;
