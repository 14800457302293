import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import i18n from '../../i18n';
import { Trans } from 'react-i18next';

class RelatedProject extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return  <section className="portfolio-three">
          <div className="block-title-two">
            <div>
              <p>{i18n.t("support")}</p>
              <h3>{i18n.t("need_help")}</h3>
            </div>
            <div className='block-right-needhelp'>
              <h4><Trans i18nKey="join_hundred_of_users" /></h4>
              <span className='need-help_icon-customer'>
                <img src={publicUrl+"assets/images/customers/sirius_le_brave.png"}/>
                <img src={publicUrl+"assets/images/customers/magnetor_64.jpeg"}/>
                <img src={publicUrl+"assets/images/customers/umset_gaming.jpeg"}/>
                <img src={publicUrl+"assets/images/customers/tybzzyyy.jpeg"}/>
                <img src={publicUrl+"assets/images/customers/sorelh.jpeg"}/>
                <img src={publicUrl+"assets/images/customers/cribstaa.jpeg"}/>
                <img src={publicUrl+"assets/images/customers/riggs.jpeg"}/>
              </span>
              <Link to="/register" className="thm-btn"> {i18n.t('button_pricing')} </Link>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row gallery-content filter-layout">
              <div className="col-lg-3 col-md-6 col-sm-12 design masonary-item">
                <div className="portfolio-three__single">
                  <div className="portfolio-three__image">
                    <img src={publicUrl+"assets/images/brand/bg/discord_bg.png"} alt={ imagealt } />
                  </div>
                  <div className="portfolio-three__content">
                    <div className="portfolio-three__content-inner">
                      <a href="https://discord.gg/yBQyqvDQQP" target="_blank" rel="noopener noreferrer" className="portfolio-three__link"><i className="fa fa-long-arrow-alt-right" /></a>
                      <h3><a href="https://discord.gg/yBQyqvDQQP" target="_blank" rel="noopener noreferrer">Discord</a></h3>
                    </div>{/* /.portfolio-three__content-inner */}
                  </div>
                </div>
              </div>{/* /.col-md-4 col-sm-12 */}
              <div className="col-lg-3 col-md-6 col-sm-12 masonary-item design">
                <div className="portfolio-three__single">
                  <div className="portfolio-three__image">
                    <img src={publicUrl+"assets/images/brand/bg/tiktok_bg.png"} alt="" />
                  </div>{/* /.portfolio-three__image */}
                  <div className="portfolio-three__content">
                    <div className="portfolio-three__content-inner">
                      <a href="https://www.tiktok.com/@live_show_app" target="_blank" rel="noopener noreferrer" className="portfolio-three__link"><i className="fa fa-long-arrow-alt-right" /></a>
                      <h3><a href="https://www.tiktok.com/@live_show_app" target="_blank" rel="noopener noreferrer">Tiktok</a></h3>
                    </div>{/* /.portfolio-three__content-inner */}
                  </div>
                </div>{/* /.portfolio-three__single */}
              </div>{/* /.col-md-4 col-sm-12 */}
              <div className="col-lg-3 col-md-6 col-sm-12 masonary-item minimal">
                <div className="portfolio-three__single">
                  <div className="portfolio-three__image">
                    <img src={publicUrl+"assets/images/brand/bg/youtube_bg.png"} alt="" />
                  </div>{/* /.portfolio-three__image */}
                  <div className="portfolio-three__content">
                    <div className="portfolio-three__content-inner">
                      <a href="https://www.youtube.com/@LiveShowApp" target="_blank" rel="noopener noreferrer" className="portfolio-three__link"><i className="fa fa-long-arrow-alt-right" /></a>
                      <h3><a href="https://www.youtube.com/@LiveShowApp" target="_blank" rel="noopener noreferrer">Youtube</a></h3>
                    </div>{/* /.portfolio-three__content-inner */}
                  </div>
                </div>{/* /.portfolio-three__single */}
              </div>{/* /.col-md-4 col-sm-12 */}
              <div className="col-lg-3 col-md-6 col-sm-12 masonary-item branding creative">
                <div className="portfolio-three__single">
                  <div className="portfolio-three__image">
                    <img src={publicUrl+"assets/images/brand/bg/email_bg.png"} alt="" />
                  </div>{/* /.portfolio-three__image */}
                  <div className="portfolio-three__content">
                    <div className="portfolio-three__content-inner">
                      <a href="mailto:contact@live-show.app" target="_blank" rel="noopener noreferrer" className="portfolio-three__link"><i className="fa fa-long-arrow-alt-right" /></a>
                      <h3><a href="mailto:contact@live-show.app" target="_blank" rel="noopener noreferrer">Email</a></h3>
                    </div>{/* /.portfolio-three__content-inner */}
                  </div>
                </div>{/* /.portfolio-three__single */}
              </div>{/* /.col-md-4 col-sm-12 */}
            </div>{/* /.row no-gutters */}
          </div>
        </section>

        }
}

export default RelatedProject