import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
class BlogDetails extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'

    return (
     <section className="blog-list blog-details">
		<Helmet>
            <title>Live Show - Terms Of Use</title>
            <meta id="metaTitle" name="title" content="Live Show - Terms Of Use" />
            <meta name="description" content="Download Live Show for more interactions with your Stream" />
            <meta name="author" content="Live Show Inc" />

            {/* Open Graph / Facebook */}
            <meta property="og:site_name" content="Live Show - Terms Of Use" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.live-show.app/" />
            <meta property="og:title" content="Live Show" />
            <meta property="og:description" content="Download Live Show for more interactions with your Stream" />
            <meta property="og:image" content="%PUBLIC_URL%/assets/logo-full-light.png" />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />

            {/* Twitter */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://www.live-show.app/" />
            <meta property="twitter:title" content="Live Show - Terms Of Use" />
            <meta property="twitter:description" content="Download Live Show for more interactions with your Stream" />
            <meta property="twitter:image" content="%PUBLIC_URL%/assets/logo-full-light.png" />

            <meta name="msapplication-TileColor" content="#00301d" />
            <meta name="msapplication-TileImage" content="%PUBLIC_URL%/assets/logo-full-light.png" />
            <meta name="theme-color" content="#00ff99" />
        </Helmet>
		  <div className="container">
		    <div className="row">
		      <div className="col-lg-15">
		        <div className="blog-one__single">
		          <div className="blog-one__content">
		            <div className="blog-one__top">
		              <div className="blog-one__author">
		                <p style={{marginLeft: -0.2 + 'px'}}>Last Update : 9 Feb. 2024</p>
		              </div>{/* /.blog-one__author */}
		            </div>{/* /.blog-one__top */}
		            <h3>Here is our Terms Of Use</h3>
		            <p>Please read these Terms of Use carefully before using our services. By accessing or using our services, you agree to be bound by these terms. If you do not agree to these terms, please do not use our services.</p>
		            <br />
					<h3>Use of our services</h3>
		            <p>You are authorized to use our services for lawful purposes and in accordance with these Terms of Use. You agree not to use our services for any unlawful or prohibited purpose under these terms.</p>
		            <br />
					<h3>Intellectual Property</h3>
					<p>All content on our services, including but not limited to text, images, graphics, logos, videos, and software, is the exclusive property of <b>ARCADIA FMC</b> or its licensors and is protected by intellectual property laws. You agree not to copy, distribute, modify, reproduce, publish, sell, or exploit in any way any content from our services without the prior written consent of <b>ARCADIA FMC</b>.</p>
		          	<br/>
					<h3>Liability</h3>
					<p>Our services are provided "as is" and "as available," without any warranties, express or implied. We do not warrant that our services will be error-free or that access to our services will be uninterrupted. You use our services at your own risk. In no event shall <b>ARCADIA FMC</b> be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising from the use of our services or the inability to use them.</p>
				  	<br/>
					<h3>Modification of the Terms</h3>
					<p>We reserve the right to modify these Terms of Use at any time. Any changes will be effective upon posting on our website. It is your responsibility to regularly review these terms for any updates.</p>
				  	<br/>
					<h3>Subscription Termination</h3>
					<p>Upon termination of your subscription, you agree that we may request you to delete any actions or data associated with your account. This includes but is not limited to any content created, uploaded, or shared during the subscription period.</p>
					<br/>
					<h3>Governing Law</h3>
					<p>These Terms of Use are governed by the laws of <b>France</b>. In the event of a dispute arising from the use of our services, the competent courts of <b>Paris/France</b> shall have exclusive jurisdiction.</p>
					<br/>
					<h3>Contact Us</h3>
					<p>If you have any questions regarding these Terms of Use, please contact us at the following email address: <b>contact@live-show.app</b>. By using our services, you agree to the terms of these Terms of Use.</p>

				  </div>{/* /.blog-one__content */}
		          <br />
		        </div>{/* /.blog-one__single */}
		      </div>{/* /.col-lg-8 */}
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>

    )
  }
}

export default BlogDetails;
