import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import i18n from '../../i18n.js'
import { Trans } from 'react-i18next';

class TestimonialV2 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'
        let CustomClass = this.props.CustomClass ? this.props.CustomClass : ''

    return  <section className={"testimonials-two "+CustomClass}>
			  <div className="container">
			    <div className="block-title-three">
			      <h3> <Trans i18nKey="clients_say2_1" /></h3>
			    </div>{/* /.block-title-two */}
			    <div className="testimonials-two__carousel owl-carousel thm__owl-carousel owl-theme" data-options="{&quot;loop&quot;: true, &quot;margin&quot;: 30, &quot;stagePadding&quot;: 0, &quot;items&quot;: 3, &quot;smartSpeed&quot;: 700, &quot;autoplay&quot;: true, &quot;autoplayTimeout&quot;: 7000, &quot;nav&quot;: false, &quot;dots&quot;: true, &quot;responsive&quot;: { &quot;0&quot;: { &quot;items&quot;: 1, &quot;stagePadding&quot;: 0 }, &quot;1199&quot;: { &quot;items&quot;: 2 }, &quot;1200&quot;: { &quot;items&quot;: 3 } }}">
			      <div className="item" style={{ cursor: 'pointer' }} onClick={() => window.open('https://www.tiktok.com/@siriuslebrave', '_blank')}>
			        <div className="testimonials-two__single">
			          <div className="testimonials-two__top">
			            <div className="testimonials-two__image">
			              <img src={publicUrl+"assets/images/customers/sirius_le_brave.png"} alt={ imagealt } />
			            </div>{/* /.testimonials-two__image */}
			            <div className="testimonials-two__top-content">
			              <h3>Sirius le brave</h3>
			              <span>{i18n.t('client1_role')}</span>
			            </div>{/* /.testimonials-two__top-content */}
			          </div>{/* /.testimonials-two__top */}
			          <div className="testimonials-two__content">
			            <p>{i18n.t('client1_p')}</p>
			          </div>{/* /.testimonials-two__content */}
			          <div className="testimonials-two__bottom">
			            <i className="far fa-quote-right testimonials-two__qoute-icon" />
			            <div className="testimonials-two__stars">
			              <i className="fas fa-star" />
			              <i className="fas fa-star" />
			              <i className="fas fa-star" />
			              <i className="fas fa-star" />
			              <i className="fas fa-star" />
			            </div>{/* /.testimonials-two__stars */}
			          </div>{/* /.testimonials-two__bottom */}
			        </div>{/* /.testimonials-two__single */}
			      </div>{/* /.item */}
			      <div className="item" style={{ cursor: 'pointer' }} onClick={() => window.open('https://www.tiktok.com/@magnetor_64', '_blank')}>
			        <div className="testimonials-two__single">
			          <div className="testimonials-two__top">
			            <div className="testimonials-two__image">
			              <img src={publicUrl+"assets/images/customers/magnetor_64.jpeg"} alt={ imagealt } />
			            </div>{/* /.testimonials-two__image */}
			            <div className="testimonials-two__top-content">
			              <h3>Magnetor_64</h3>
			              <span>{i18n.t('client1_role')}</span>
			            </div>{/* /.testimonials-two__top-content */}
			          </div>{/* /.testimonials-two__top */}
			          <div className="testimonials-two__content">
			            <p>{i18n.t('client2_p')}</p>
			          </div>{/* /.testimonials-two__content */}
			          <div className="testimonials-two__bottom">
			            <i className="far fa-quote-right testimonials-two__qoute-icon" />
			            <div className="testimonials-two__stars">
			              <i className="fas fa-star" />
			              <i className="fas fa-star" />
			              <i className="fas fa-star" />
			              <i className="fas fa-star" />
			              <i className="far fa-star" />
			            </div>{/* /.testimonials-two__stars */}
			          </div>{/* /.testimonials-two__bottom */}
			        </div>{/* /.testimonials-two__single */}
			      </div>{/* /.item */}
			      <div className="item" style={{ cursor: 'pointer' }} onClick={() => window.open('https://www.tiktok.com/@umset_rl', '_blank')}>
			        <div className="testimonials-two__single">
			          <div className="testimonials-two__top">
			            <div className="testimonials-two__image">
			              <img src={publicUrl+"assets/images/customers/umset_gaming.jpeg"} alt={ imagealt } />
			            </div>{/* /.testimonials-two__image */}
			            <div className="testimonials-two__top-content">
			              <h3>Umset Gaming</h3>
			              <span>{i18n.t('client1_role')}</span>
			            </div>{/* /.testimonials-two__top-content */}
			          </div>{/* /.testimonials-two__top */}
			          <div className="testimonials-two__content">
			            <p>{i18n.t('client3_p')}</p>
			          </div>{/* /.testimonials-two__content */}
			          <div className="testimonials-two__bottom">
			            <i className="far fa-quote-right testimonials-two__qoute-icon" />
			            <div className="testimonials-two__stars">
			              <i className="fas fa-star" />
			              <i className="fas fa-star" />
			              <i className="fas fa-star" />
			              <i className="fas fa-star" />
			              <i className="fas fa-star" />
			            </div>{/* /.testimonials-two__stars */}
			          </div>{/* /.testimonials-two__bottom */}
			        </div>{/* /.testimonials-two__single */}
			      </div>{/* /.item */}
			      
			    </div>{/* /.testimonials-two__carousel owl-carousel thm__owl-carousel owl-theme */}
			  </div>{/* /.container */}
			</section>

			 
        }
}

export default TestimonialV2