import React, { Component } from 'react';
import parse from 'html-react-parser';
import i18n from '../../i18n.js'

import axios from "axios";

class Funfact extends Component {

	state = {
		numberOfUsers: null,
		error: null
	  };

	componentDidMount()
	{
		// Effectuer la requête GET lorsque le composant est monté
		axios.get("https://api.live-show.app/user/get_number_of_users")
		.then(response => {
			// Mettre à jour l'état avec le nombre d'utilisateurs
			this.setState({ numberOfUsers: response.data });
		})
		.catch(error => {
			// Gérer les erreurs
			this.setState({ error: error.message });
		});

	}

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

		const { numberOfUsers, error } = this.state;

    return <section className="funfact-one">
			<div className="container">
				<div className="row funfact__flex">
					<div className="col-">
						<div className="funfact-one__single">
							<div className="funfact-one__icon">
								<i class="fas fa-gem"></i>
							</div>{/* /.funfact-one__icon */}
							<span>
								<h3>+<span className="counter">30</span>{/* /.counter */}%</h3>
								<p>{i18n.t("of_diamonds")}</p>
							</span>
							
						</div>{/* /.funfact-one__single */}
					</div>{/* /.col-lg-3 col-md-6 */}
					<div className="col-">
						<div className="funfact-one__single">
							<div className="funfact-one__icon">
								<i className="far fa-user-alt" />
							</div>{/* /.funfact-one__icon */}
							<span className='funfact_leftText'>
								<h3>+<span className="counter">{numberOfUsers}</span>{/* /.counter */}</h3>
								<p>{i18n.t("Membership")}</p>
							</span>
						</div>{/* /.funfact-one__single */}
					</div>{/* /.col-lg-3 col-md-6 */}
					<div className="col-">
						<div className="funfact-one__single">
							<div className="funfact-one__icon">
								<i class="far fa-thumbs-up"></i>
							</div>{/* /.funfact-one__icon */}
							<span>
							<h3>+<span className="counter">50</span>%</h3>
							<p>{i18n.t("viewers_on_your_live")}</p>
							</span>
							
						</div>{/* /.funfact-one__single */}
					</div>{/* /.col-lg-3 col-md-6 */}
					
				</div>{/* /.row */}
			</div>{/* /.container */}
		</section>


        }
}

export default Funfact