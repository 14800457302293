import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import i18n from '../../i18n.js'

class AboutV6 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <section className="about-three about-three__about-page  go-top">
			  <div className="container">
			    <div className="row">
			      <div className="col-xl-6">
			        <div className="about-three__content">
			          <div className="block-title-two text-left">
			            <p>{i18n.t('what_we_do')}</p>
			            <h3>{i18n.t('aboutv6_title')} </h3>
			          </div>{/* /.block-title-two */}
			          <p>{i18n.t('aboutv6_p_0')}</p>
			          <h4><i class="fas fa-wrench"></i> &nbsp;&nbsp;{i18n.t('aboutv6_subtitle_1')}</h4>
			          <p>{i18n.t('aboutv6_p_1')}</p>
					  <h4><i class="fas fa-laptop-code"></i> &nbsp;&nbsp;{i18n.t("aboutv6_subtitle_2")}</h4>
			          <p>{i18n.t("aboutv6_p_2")}</p>
			          {/* /.thm-btn about-three__btn */}
			        </div>{/* /.about-three__content */}
			      </div>{/* /.col-lg-6 */}
			      <div className="col-xl-6 d-flex justify-content-center">
			        <div className="video-one__box">
			          <img src={publicUrl+"assets/images/mock/dashboard_laptop.png"} alt={ imagealt } />
			          <a href={publicUrl+"assets/video/Final_Trailer.mp4"} className="video-popup video-one__btn"><i className="fa fa-play" /></a>{/* /.video-popup video-one__btn */}
			        </div>{/* /.video-one__box */}
			      </div>{/* /.col-lg-6 */}
			    </div>{/* /.row */}
			  </div>{/* /.container */}
			</section>
        }
}

export default AboutV6