import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next"
import i18n from '../../i18n.js'
import { Trans } from 'react-i18next';
import Cookies from 'js-cookie';

class Navbar extends Component {
	
    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
        let imgattr = 'logo'
        let anchor = '#'

		const changeLangHandler = (lang) => {
			i18n.changeLanguage(lang)
			Cookies.set('language', lang, { expires: 365 * 9999, secure: true })
			window.location.reload()
		}

        return (
           <header className="site-header-one go-top">
			  	<span className='ruban-top'>
					<p><Trans i18nKey="text_left" /></p>
					<span className='ruban-top__right'>
						<p><Trans i18nKey="follow_us" /></p>
						
						<span className='brandList'>
							<a href="https://www.youtube.com/@LiveShowApp" target="_blank" rel="noopener noreferrer">
								<svg className='youtube' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
								<path d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z"/>
								</svg>
							</a>
							<a href="https://discord.gg/yBQyqvDQQP" target="_blank" rel="noopener noreferrer">
								<svg className='discord' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
								<path d="M524.5 69.8a1.5 1.5 0 0 0 -.8-.7A485.1 485.1 0 0 0 404.1 32a1.8 1.8 0 0 0 -1.9 .9 337.5 337.5 0 0 0 -14.9 30.6 447.8 447.8 0 0 0 -134.4 0 309.5 309.5 0 0 0 -15.1-30.6 1.9 1.9 0 0 0 -1.9-.9A483.7 483.7 0 0 0 116.1 69.1a1.7 1.7 0 0 0 -.8 .7C39.1 183.7 18.2 294.7 28.4 404.4a2 2 0 0 0 .8 1.4A487.7 487.7 0 0 0 176 479.9a1.9 1.9 0 0 0 2.1-.7A348.2 348.2 0 0 0 208.1 430.4a1.9 1.9 0 0 0 -1-2.6 321.2 321.2 0 0 1 -45.9-21.9 1.9 1.9 0 0 1 -.2-3.1c3.1-2.3 6.2-4.7 9.1-7.1a1.8 1.8 0 0 1 1.9-.3c96.2 43.9 200.4 43.9 295.5 0a1.8 1.8 0 0 1 1.9 .2c2.9 2.4 6 4.9 9.1 7.2a1.9 1.9 0 0 1 -.2 3.1 301.4 301.4 0 0 1 -45.9 21.8 1.9 1.9 0 0 0 -1 2.6 391.1 391.1 0 0 0 30 48.8 1.9 1.9 0 0 0 2.1 .7A486 486 0 0 0 610.7 405.7a1.9 1.9 0 0 0 .8-1.4C623.7 277.6 590.9 167.5 524.5 69.8zM222.5 337.6c-29 0-52.8-26.6-52.8-59.2S193.1 219.1 222.5 219.1c29.7 0 53.3 26.8 52.8 59.2C275.3 311 251.9 337.6 222.5 337.6zm195.4 0c-29 0-52.8-26.6-52.8-59.2S388.4 219.1 417.9 219.1c29.7 0 53.3 26.8 52.8 59.2C470.7 311 447.5 337.6 417.9 337.6z"/>
								</svg>
							</a>
							<a href="https://www.tiktok.com/@live_show_app" target="_blank" rel="noopener noreferrer">
								<svg className='tiktok' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
								<path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z"/>
								</svg>
							</a>
						</span>
					</span>
				</span>	
			  <nav className="main-nav__one stricky">
			    <div className="container-fluid">
			      <div className="main-nav__logo-box">
			        <Link to="/">
			          <img src={publicUrl+"assets/images/logo-full-light.png" } alt={ imgattr } />
			        </Link>
			        <a href="#" className="side-menu__toggler"><i className="fa fa-bars" /></a>
			      </div>{/* /.main-nav__logo-box */}
			      <div className="main-nav__main-navigation">
			        <ul className=" main-nav__navigation-box">
					  <li>
					  <img src={publicUrl+"assets/images/logo_icon_white.svg" } alt={ imgattr } />
						<Link to="/">{i18n.t("home")}</Link>
					  </li>
					  <li><Link to="/Download">{i18n.t("download")}</Link></li>
					  <li><Link to="/register">{i18n.t("sign_up")}</Link></li>
			          <li><Link to="/pricing">{i18n.t("pricing")}</Link></li>			  
					  <li><Link to="/news">{i18n.t("news")}</Link></li>
					  <li><Link to="/faq">{i18n.t("faq")}</Link></li>
					  <li className="dropdown">
			            <Link>{i18n.t("language")}</Link>
			            <ul style={{cursor:"pointer"}}>
			              <li onClick={(() => changeLangHandler("en"))}><a>🌎  EN</a></li>
			              <li onClick={(() => changeLangHandler("fr"))}><a>🥐  FR</a></li>
			              <li onClick={(() => changeLangHandler("it"))}><a>🍕  IT</a></li>
			            </ul>
			          </li>	
					  {/* 
			          <li className="dropdown">
			            <Link to="/blog-standard">News</Link>
			            <ul>
			              <li><Link to="/blog-grid">News Page</Link></li>
			              <li><Link to="/blog-list">News List</Link></li>
			              <li><Link to="/blog-details">News Details</Link></li>
			            </ul>
			          </li>
			          <li>
			            <Link to="/contact">Contact</Link>
			          </li>
					  */}
			        </ul>
			      </div>{/* /.main-nav__main-navigation */}
			      <div className="main-nav__right">
			        <Link to="/register" className="thm-btn main-nav__btn">{i18n.t("create_account")} <i className="fa fa-angle-right" /></Link>
			        {/* /.thm-btn */}
			      </div>{/* /.main-nav__right */}
			    </div>
			  </nav>
			</header>
        )
    }
}


export default Navbar