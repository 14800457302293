import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import BlogDetails from './blog-components/blog-details';
import Footer from './global-components/footer-v4';
import i18n from "../i18n"
import articleFiles from "../constants/articles"

function getTitleFromFile(fileName) {
    const foundFile = articleFiles.find(article => article.file === fileName);
    return foundFile ? foundFile.title : "File not found";
}

const BlogList = () => {

    const url = window.location.href;
    const parts = url.split('/');
    let release = parts[parts.length - 1] + ".md";

    release = getTitleFromFile(release);

    return <div>
        <Navbar />
        <PageHeader headertitle={release}  />
        <BlogDetails />
        <Footer />
    </div>
}

export default BlogList

