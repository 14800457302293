import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Policy from './blog-components/privacy_policy';
import Footer from './global-components/footer-v4';
import i18n from "../i18n"

const AboutPage = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle={i18n.t("privacy_policy")}  />
        <Policy/>
        <Footer />
    </div>
}

export default AboutPage

