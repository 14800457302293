import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import i18n from '../../i18n.js'

class FooterV4 extends Component {

  componentDidMount() {
    const $ = window.$;

    let publicUrl = process.env.PUBLIC_URL + '/'
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/theme.js";

    document.body.appendChild(minscript);

    $('.go-top').find('a').on('click', function () {
      $(window).scrollTop(0);
    });


  }

  render() {

    let publicUrl = process.env.PUBLIC_URL + '/'
    let imgattr = "Footer logo"

    return (
      <footer className="site-footer-four  go-top">
        <div className="site-footer-four__bubble-1" />{/* /.site-footer-four__bubble-1 */}
        <div className="site-footer-four__bubble-2" />{/* /.site-footer-four__bubble-2 */}
        <div className="site-footer-four__bubble-3" />{/* /.site-footer-four__bubble-3 */}
        <div className="site-footer-four__bubble-4" />{/* /.site-footer-four__bubble-4 */}
        
        <div className="site-footer-four__upper">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="footer-widget footer-widget__about">
                  <Link to="/">
                    <img src={publicUrl + "assets/images/logo-full-light.png"} alt={imgattr} />
                  </Link>
                  <p>{i18n.t("footer_banner_p")}</p>
                </div>{/* /.footer-widget footer-widget__about */}
              </div>{/* /.col-lg-4 */}
              <div className="col-lg-4">
                
                <div className="footer-widget footer-widget__links">
                  <h3 className="footer-widget__title">{i18n.t("company")}</h3>
                  <ul className="list-unstyled white-space__nowrap">
                    <li><Link to="/about">{i18n.t("about_us")}</Link></li>
                    <li><Link to="/about"></Link></li>
                    <li><Link to="/terms_of_use">{i18n.t("terms_of_use")}</Link></li>
                    <li><Link to="/about"></Link></li>
                    <li><Link to="/privacy_policy">{i18n.t("privacy_policy")}</Link></li>
                    <li><Link to="/about"></Link></li>
                    <li><Link to="/refund_policy">{i18n.t("refund_policy")}</Link></li>
                  </ul>
                </div>
              </div>{/* /.col-lg-4 */}
              <div className="col-lg-4">
                <div className="footer-widget">
                  <ul className="footer-widget__menu list-unstyled">
                    <li><Link to="/">{i18n.t("home")}</Link></li>
                    <li><Link to="/about">{i18n.t("feature")}</Link></li>
                    <li><Link to="/pricing">{i18n.t("pricing")}</Link></li>
                    <li><Link to="/download">{i18n.t("download")}</Link></li>
                  </ul>{/* /.footer-widget__menu list-unstyled */}
                  <div className="footer-widget__social">

                  </div>{/* /.footer-widget__social */}
                </div>{/* /.footer-widget */}
              </div>{/* /.col-lg-4 */}
            </div>{/* /.row */}
          </div>{/* /.container */}
        </div>{/* /.site-footer-four__upper */}
        <div className="site-footer-four__bottom">
          <div className="container text-center">
            <div className="site-footer-four__bottom-sep" />{/* /.site-footer-four__upper-sep */}
            <p>@ Copyright 2024 <b>ARCADIA FMC</b>. All Right Reserved.</p>
          </div>{/* /.container */}
        </div>{/* /.site-footer-four__bottom */}
      </footer>

    )
  }
}


export default FooterV4