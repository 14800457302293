import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class BlogDetails extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'

    return (
     <section className="blog-list blog-details">
		  <div className="container banner-download">
			<h1 style={{textAlign: 'center', fontSize: 200, marginTop: -100 + "px", color:"black", fontWeight: 700, fontFamily:"Montserrat Alternates Bold"}}>404</h1>
			<h1 style={{textAlign: 'center', marginTop: -40 + "px", color:"black", fontWeight: 600, fontFamily:"Montserrat Alternates Bold"}}>Oops! This page could not be found.</h1>
			<h1 style={{textAlign: 'center', marginTop: 20 + "px", fontSize: 16, fontFamily:"Montserrat Alternates Bold"}}>The page you are looking for might have been removed or had its name changed or is temporarily unavailable.</h1>
		  	
			<Link style={{marginTop: 20 + "px", fontFamily:"Montserrat Alternates Bold"}} to="/" className="thm-btn banner-four__btn">Back To Home</Link>{/* /.thm-btn banner-four__btn */}
		  </div>{/* /.container */}
		</section>

    )
  }
}

export default BlogDetails;
