import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import BlogGridView from './blog-components/blog-list';
import Footer from './global-components/footer-v4';
import i18n from "../i18n"

const BlogList = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle={i18n.t("news")} />
        <BlogGridView />
        <Footer />
    </div>
}

export default BlogList

