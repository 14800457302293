import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import i18n from '../../i18n';

class WhyChooseUsV2 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return  <section className="cta-three  go-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 d-flex">
                  <div className="my-auto">
                    <div className="cta__animation">
                    <div className="pricing-two__single pricing-two__single__HP">
                      <div className="pricing-two__top">
                        <div className="pricing-two__bubble-1" />{/* /.pricing-two__bubble-1 */}
                        <div className="pricing-two__bubble-2" />{/* /.pricing-two__bubble-2 */}
                        <div className="pricing-two__bubble-3" />{/* /.pricing-two__bubble-3 */}
                        <div className="pricing-two__bubble-4" />{/* /.pricing-two__bubble-4 */}
                        <img src={publicUrl+"assets/images/shapes/pricing-shape-1-1.png"} className="pricing-two__shape" alt={ imagealt } />
                        <h3>{i18n.t("free_usage")}</h3>
                        <img src={publicUrl+"assets/images/shapes/pricing-i-2-1.png"} alt={ imagealt } />
                      </div>{/* /.pricing-two__top */}
                      <div className="pricing-two__bottom">
                        <ul className="list-unstyled pricing-two__list">
                          <li><i className="far fa-check" />{i18n.t("limit_actions")}</li>
                          <li><i className="far fa-check" />{i18n.t("limit_sounds")}</li>
                          <li><i className="far fa-check" />{i18n.t("limit_widgets")}</li>
                          <li><i className="far fa-check" />{i18n.t("limit_stream_keys")}</li>
                        </ul>{/* /.list-unstyled pricing-two__list */}
                        <p> {i18n.t("free")}</p>
                        <div className="text-center">
                          <Link to="/download" className="thm-btn pricing-two__btn">{i18n.t("download")} <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn pricing-one__btn */}
                        </div>{/* /.text-center */}
                      </div>{/* /.pricing-two__bottom */}
                    </div>{/* /.pricing-two__single */}
                    </div>{/* /.cta-three__image */}
                  </div>{/* /.my-auto */}
                </div>{/* /.col-lg-6 */}
                <div className="col-lg-6">
                  <div className="cta-three__content  go-top">
                    <div className="block-title-two text-left">
                      <p>{i18n.t("why_choose_us")}</p>
                      <h3> {i18n.t("live_from_your_live")} <br/> 
                      {i18n.t("easy_liveshow_its_simple")}</h3>
                    </div>{/* /.block-title-two */}
                    <div className="cta-three__box-wrap">
                      <div className="cta-three__box">
                        <div className="cta-three__box-icon">
                          <i className="far fa-tachometer" />
                        </div>{/* /.cta-three__icon */}
                        <div className="cta-three__box-content">
                          <h3>{i18n.t("enhance_interaction")}</h3>
                          <p>{i18n.t("enhance_interaction_p")}</p>
                        </div>{/* /.cta-three__box-content */}
                      </div>{/* /.cta-three__box */}
                      <div className="cta-three__box">
                        <div className="cta-three__box-icon">
                          <i className="far fa-star-of-david" />
                        </div>{/* /.cta-three__icon */}
                        <div className="cta-three__box-content">
                          <h3>{i18n.t("foster_engagement")}</h3>
                          <p>{i18n.t("foster_engagement_p")}</p>
                        </div>{/* /.cta-three__box-content */}
                      </div>{/* /.cta-three__box */}
                      <div className="cta-three__box">
                        <div className="cta-three__box-icon">
                          <i className="fa fa-desktop" />
                        </div>{/* /.cta-three__icon */}
                        <div className="cta-three__box-content">
                          <h3>{i18n.t("facilitate_collaboration")}</h3>
                          <p>{i18n.t("facilitate_collaboration_p")}</p>
                        </div>{/* /.cta-three__box-content */}
                      </div>{/* /.cta-three__box */}
                    </div>{/* /.cta-three__box-wrap */}
                    <Link to="/pricing" className="thm-btn cta-three__btn">{i18n.t("check_pricing")} <i className="fa fa-angle-double-right" /></Link>
                    {/* /.thm-btn cta-three__btn */}
                  </div>{/* /.cta-three__content */}
                </div>{/* /.col-lg-6 */}
              </div>{/* /.row */}
            </div>{/* /.container */}
          </section>


        }
}

export default WhyChooseUsV2