import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import i18n from '../../i18n.js'

class BannerV4 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <section className="banner-pricing  go-top ">
		<img className='before' src={publicUrl+"assets/images/shapes/banner-4-bg-curve.png"}></img>
		  <div className="container-fluid">
		    <div className="row">
		      <div className="col-lg-12" style={{position: 'relative', top: '-100px'}}>
		        <div className="banner-four__content banner-download">
		          <h4>{i18n.t("success_your_lives")}</h4>
		          <h3>{i18n.t("banner_liveonline_title_1")} <br />
		            <span>{i18n.t("banner_liveonline_title_2")}</span></h3>
		        </div>{/* /.banner-four__content */}
		      </div>{/* /.col-lg-12 */}
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>

        }
}

export default BannerV4