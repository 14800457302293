import React, { Component } from 'react';
import { Trans } from 'react-i18next';

class TestimonialV2 extends Component {
    constructor(props) {
        super(props);
        // Initialisation de l'état pour l'iframe actuellement sélectionné
        this.state = {
            iframeSrc: 'video1' // Valeur par défaut pour l'iframe, utilisant des identifiants
        };
    }

    // Fonction pour changer l'iframe en fonction de l'identifiant
    handleIframeChange = (videoId) => {
        this.setState({ iframeSrc: videoId });
    }

    renderIframe = () => {
        const { iframeSrc } = this.state;
        const publicUrl = process.env.PUBLIC_URL + '/';

        // Conditions pour retourner différents iframes en fonction de l'état
        switch (iframeSrc) {
            case 'video1':
                return (
                    <iframe 
                        className='iframeHorizontaly'
                        src="https://www.youtube.com/embed/NFQH2FjdoC0" 
                        title="Video 1"
                        frameBorder="0"
                        allowFullScreen
                    ></iframe>
                );
            case 'video2':
                return (
                    <iframe 
						className='iframeHorizontaly'
                        src="https://www.youtube.com/embed/TxDH5YIv4ZM" 
                        title="Video 2"
                        frameBorder="0"
                        allowFullScreen
                    ></iframe>
                    
                );
            case 'video3':
                return (
                    <iframe 
                        className='iframeHorizontaly'
                        src="https://www.youtube.com/embed/CAG2jceQE9o" 
                        title="Video 3"
                        frameBorder="0"
                        allowFullScreen
                    ></iframe>
                );
            default:
                return (
                    <p>Veuillez sélectionner une vidéo.</p>
                );
        }
    }

    render() {
        let CustomClass = this.props.CustomClass ? this.props.CustomClass : '';

        return (
            <section className={"whyChooseUsV3 " + CustomClass}>
                <div className="block-title-three">
                    <h3><Trans i18nKey="why_choose_us_v3_title" /></h3>
                    <p><Trans i18nKey="why_choose_us_v3_description" /></p>
                </div>
                <div className="whyChooseUsV3_navbar">
                    <span
                        className={this.state.iframeSrc === 'video1' ? 'whyChooseUsV3_navbarActive' : ''}
                        onClick={() => this.handleIframeChange('video1')}
                    >
                        <Trans i18nKey="why_choose_us_v3_game_1" />
                    </span>
                    <span
                        className={this.state.iframeSrc === 'video2' ? 'whyChooseUsV3_navbarActive' : ''}
                        onClick={() => this.handleIframeChange('video2')}
                    >
                        <Trans i18nKey="why_choose_us_v3_game_2" />
                    </span>
                    <span
                        className={this.state.iframeSrc === 'video3' ? 'whyChooseUsV3_navbarActive' : ''}
                        onClick={() => this.handleIframeChange('video3')}
                    >
                        <Trans i18nKey="why_choose_us_v3_game_3" />
                    </span>
                </div>
                <div className='whyChooseUsV3_iframeWrapper'>
                    {this.renderIframe()} {/* Appel de la méthode pour rendre l'iframe conditionnel */}
                </div>
            </section>
        );
    }
}

export default TestimonialV2;
