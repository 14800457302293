import React from 'react';
import Navbar from './global-components/navbar';
import BannerRegister from './section-components/banner-register';
import AboutStyleOne from './section-components/about-style-one';
import AboutStyleTwo from './section-components/about-style-two';
import AboutV3 from './section-components/about-v6';
import CTA10 from './section-components/cta-v10';
import Footer from './global-components/footer-v4';

const ContactPage = () => {
    return <div>
        <Navbar />
        <BannerRegister/>
        <AboutV3/>
        <CTA10/>
        <AboutStyleOne />
        <AboutStyleTwo />
        
        <Footer />
    </div>
}

export default ContactPage

