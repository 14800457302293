import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import i18n from "../../i18n"

class FaqV3 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return  <section className="faq-one faq-one__faq-page">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="faq-one__block">
                  <div className="block-title-two text-left">
                    <h3>{i18n.t("about_our_company")}</h3>
                    <span>{i18n.t("we_are_pro")}</span>
                  </div>{/* /.block-title-two */}
                  <div className="accrodion-grp" data-grp-name="faq-one-accrodion">
                    <div className="accrodion ">
                      <div className="accrodion-title">
                        <h4>{i18n.t("faq_q1")}</h4>
                      </div>
                      <div className="accrodion-content">
                        <div className="inner">
                          <p>
                            {i18n.t("faq_q1_answer")}
                          </p>
                        </div>{/* /.inner */}
                      </div>
                    </div>
                    <div className="accrodion active">
                      <div className="accrodion-title">
                        <h4>{i18n.t("faq_q2")}</h4>
                      </div>
                      <div className="accrodion-content">
                        <div className="inner">
                          <p>
                          {i18n.t("faq_q2_answer")}</p>
                        </div>{/* /.inner */}
                      </div>
                    </div>
                    <div className="accrodion">
                      <div className="accrodion-title">
                        <h4>{i18n.t("faq_q3")}</h4>
                      </div>
                      <div className="accrodion-content">
                        <div className="inner">
                          <p>
                          {i18n.t("faq_q3_answer")}
                          </p>
                        </div>{/* /.inner */}
                      </div>
                    </div>
                  </div>
                </div>{/* /.faq-one__block */}
                <div className="faq-one__block">
                  <div className="block-title-two text-left">
                    <h3>{i18n.t("how_it_work")}</h3>
                  </div>
                  <div className="accrodion-grp" data-grp-name="faq-two-accrodion">
                    <div className="accrodion ">
                      <div className="accrodion-title">
                        <h4>{i18n.t("faq_q4")}</h4>
                      </div>
                      <div className="accrodion-content">
                        <div className="inner">
                          <p>{i18n.t("faq_q4_answer")}</p>
                        </div>
                      </div>
                    </div>
                    <div className="accrodion active">
                      <div className="accrodion-title">
                        <h4>{i18n.t("faq_q5")}</h4>
                      </div>
                      <div className="accrodion-content">
                        <div className="inner">
                          <p>{i18n.t("faq_q5_answer")}</p>
                        </div>
                      </div>
                    </div>
                    <div className="accrodion">
                      <div className="accrodion-title">
                        <h4>{i18n.t("faq_q6")}</h4>
                      </div>
                      <div className="accrodion-content">
                        <div className="inner">
                          <p>{i18n.t("faq_q6_answer")}</p>
                        </div> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </section>


        }
}

export default FaqV3