import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Error from './blog-components/404_error';
import Footer from './global-components/footer-v4';

const AboutPage = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="404 Error"  />
        <Error/>
        <Footer />
    </div>
}

export default AboutPage

