import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import i18n from '../../i18n';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class VideoV2 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return  <section className="video-two">
              <div className="video-two__block">
                <h3> <Trans i18nKey="title_video_v2" /> </h3>
              </div>{/* /.video-two__block */}
              <iframe src="https://www.youtube.com/embed/BsIcfUoO9xQ" frameborder="0"></iframe>
              <Link to="/register" className="thm-btn"> {i18n.t('sign_up_now')} </Link>
            </section>


        }
}

export default VideoV2