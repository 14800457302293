import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import parse from 'html-react-parser';
import i18n from '../../i18n';

class BannerV4 extends Component {
    handleSignIn = async (event) => {
        event.preventDefault(); // Empêcher la soumission par défaut du formulaire

        const username = document.getElementById("username").value;
        const email = document.getElementById("email").value;
        const password1 = document.getElementById("password1").value;
        const password2 = document.getElementById("password2").value;

        if (username && email && password1 && password2 && password1 === password2) {
            try {
                const response = await fetch('https://api.live-show.app/user/create', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        Username: username,
                        Email: email,
                        Password: password1,
                        IsStayConnected: false
                    })
                });
                if (response.ok) {
                    // Redirection vers une autre page
                    this.props.history.push('/register_success');
                }
				else if(response.status == 404)
				{
					const errorLabel = document.querySelector('.error-signup');
					errorLabel.innerHTML = '<i class="fas fa-exclamation-triangle"></i>&nbsp;' + " Email not valid !";
					errorLabel.classList.add('error-signup-enable');
				}
				else if(response.status == 409)
				{
					const errorLabel = document.querySelector('.error-signup');
					errorLabel.innerHTML = '<i class="fas fa-exclamation-triangle"></i>&nbsp;' + " This email is already registered !";
					errorLabel.classList.add('error-signup-enable');
				}
				else if(response.status == 408)
				{
					const errorLabel = document.querySelector('.error-signup');
					errorLabel.innerHTML = '<i class="fas fa-exclamation-triangle"></i>&nbsp;' + " Retry in few minutes !";
					errorLabel.classList.add('error-signup-enable');
				}
            } catch (error) {
                console.error('Erreur lors de la requête POST:', error);
            }
        } else {
			const errorLabel = document.querySelector('.error-signup');
			errorLabel.innerHTML = '<i class="fas fa-exclamation-triangle"></i>&nbsp;' + " Ensure to complete all inputs !";
			errorLabel.classList.add('error-signup-enable');
        }
    }

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <section className="banner-four banner-register-section  go-top">
		<img src={publicUrl+"assets/images/shapes/banner-4-bg-curve.png"} alt={ imagealt } className="banner_bottom"/>
		  <div className="container-fluid">
		    <div className="row">
		      <div className="col-lg-12">
		        <div className="banner-four__content banner-register">
					<h3><span>{i18n.t('join_us')}</span></h3>
					<div class="containerRegister">
						<div class="heading">{i18n.t('sign_up')}</div>
                        <label className='error-signup'><i class="fas fa-exclamation-triangle"></i> &nbsp; test</label>
						<form onSubmit={this.handleSignIn} class="form">
							<input required="" class="input" type="username" name="username" id="username" placeholder={i18n.t('username')}/>
							<input required="" class="input" type="email" name="email" id="email" placeholder={i18n.t('email')} />
							<span className='formFlex'>
								<input required="" class="input" type="password" name="password1" id="password1" placeholder={i18n.t('password')} />
								<input required="" class="input" type="password" name="password2" id="password2" placeholder={i18n.t('confirm_password')} />
							</span>
							<input class="login-button" type="submit" value={i18n.t('sign_up')} />
						</form>
						<span class="agreement"><Link to="/terms_of_use">{i18n.t("learn_user_license_agreement")}</Link></span>
					</div>
		        </div>{/* /.banner-four__content */}
		      </div>{/* /.col-lg-12 */}
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>

        }
}

export default withRouter(BannerV4)