import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import i18n from '../../i18n';

class BannerV4 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

		function gtag(){window.dataLayer.push(arguments);}; 

		function gtag_report_conversion(url) { 
			var callback = function () 
			{ 
				if (typeof(url) != 'undefined') 
				{ 
					window.location = url; 
				} 
			}; 
			gtag('event', 'conversion', { 'send_to': 'AW-16622524381/rN4eCOPSpL4ZEN2vnvY9', 'value': 1.0, 'currency': 'EUR', 'event_callback': callback }); 
			return false; 
		} 

    return <section className="banner-four banner-download  go-top">
		<img src={publicUrl+"assets/images/shapes/banner-4-bg-curve.png"} alt={ imagealt } className="banner_bottom"/>
		  <div className="container-fluid">
		    <div className="row">
		      <div className="col-lg-12">
		        <div className="banner-four__content banner-download">
		          <h4>{i18n.t('success_your_lives')}</h4>
		          <h3>{i18n.t('download')} Live Show<br />
		            <span>{i18n.t('for_more_interactions')}</span></h3>
		          <a onClick={gtag_report_conversion('https://update.live-show.app/live-show-setup.exe')} href="https://update.live-show.app/live-show-setup.exe" className="thm-btn banner-four__btn">{i18n.t("home_banner_download_now")} <i className="fa fa-angle-double-right" /></a>{/* /.thm-btn banner-four__btn */}
		        </div>{/* /.banner-four__content */}
		      </div>{/* /.col-lg-12 */}
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>

        }
}

export default BannerV4