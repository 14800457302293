import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Term from './blog-components/terms_of_use';
import FunfactV4 from './section-components/funfact';
import Cta from './section-components/cta-v10';
import Team from './section-components/team-v2';
import Testimonial from './section-components/testimonial-style-one';
import Brand from './section-components/brand-v4';
import AboutV6 from './section-components/about-v6';
import Footer from './global-components/footer-v4';
import i18n from "../i18n"

const AboutPage = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle={i18n.t("terms_of_use")}  />
        <Term/>
        <Footer />
    </div>
}

export default AboutPage

