import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Faqpage from './section-components/faq-v3';
import Footer from './global-components/footer-v4';
import { Helmet } from 'react-helmet';
import i18n from "../i18n"

const Faq = () => {
    return <div>
        <Helmet>
            <title>Live Show FAQ</title>
            <meta id="metaTitle" name="title" content="Live Show FAQ" />
            <meta name="description" content="Download Live Show for more interactions with your Stream" />
            <meta name="author" content="Live Show Inc" />

            {/* Open Graph / Facebook */}
            <meta property="og:site_name" content="Live Show FAQ" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.live-show.app/" />
            <meta property="og:title" content="Live Show" />
            <meta property="og:description" content="Download Live Show for more interactions with your Stream" />
            <meta property="og:image" content="%PUBLIC_URL%/assets/logo-full-light.png" />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />

            {/* Twitter */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://www.live-show.app/" />
            <meta property="twitter:title" content="Live Show FAQ" />
            <meta property="twitter:description" content="Download Live Show for more interactions with your Stream" />
            <meta property="twitter:image" content="%PUBLIC_URL%/assets/logo-full-light.png" />

            <meta name="msapplication-TileColor" content="#00301d" />
            <meta name="msapplication-TileImage" content="%PUBLIC_URL%/assets/logo-full-light.png" />
            <meta name="theme-color" content="#00ff99" />
        </Helmet>
        <Navbar />
        <PageHeader headertitle={i18n.t("ask_questions")} subheader={i18n.t("faq")} />
        <Faqpage />
        <Footer />
    </div>
}

export default Faq

