import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
class BlogDetails extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'

    return (
     <section className="blog-list blog-details">
		<Helmet>
            <title>Live Show - Refund Policy</title>
            <meta id="metaTitle" name="title" content="Live Show - Privacy Policy" />
            <meta name="description" content="Download Live Show for more interactions with your Stream" />
            <meta name="author" content="Live Show Inc" />

            {/* Open Graph / Facebook */}
            <meta property="og:site_name" content="Live Show - Privacy Policy" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.live-show.app/" />
            <meta property="og:title" content="Live Show" />
            <meta property="og:description" content="Download Live Show for more interactions with your Stream" />
            <meta property="og:image" content="%PUBLIC_URL%/assets/logo-full-light.png" />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />

            {/* Twitter */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://www.live-show.app/" />
            <meta property="twitter:title" content="Live Show - Privacy Policy" />
            <meta property="twitter:description" content="Download Live Show for more interactions with your Stream" />
            <meta property="twitter:image" content="%PUBLIC_URL%/assets/logo-full-light.png" />

            <meta name="msapplication-TileColor" content="#00301d" />
            <meta name="msapplication-TileImage" content="%PUBLIC_URL%/assets/logo-full-light.png" />
            <meta name="theme-color" content="#00ff99" />
        </Helmet>
		  <div className="container">
		    <div className="row">
		      <div className="col-lg-15">
		        <div className="blog-one__single">
		          <div className="blog-one__content">
		            <div className="blog-one__top">
		              <div className="blog-one__author">
		                <p style={{marginLeft: -0.2 + 'px'}}>Last Update : 4 Mai 2024</p>
		              </div>{/* /.blog-one__author */}
		            </div>{/* /.blog-one__top */}
		            <h3>Here is our Refund Policy</h3>
		            <p>We strive to provide exceptional service to our subscribers. We understand that circumstances may require a refund. This policy sets out our refund terms and how to request one.</p>
		            <br />
					      <h3>Eligible subscriptions:</h3>
		            <ul>
                  <li>If recurring technical problems prevent access to the service, despite attempts to resolve it.</li>
                  <li>If the service does not meet reasonable expectations defined by the subscription contract.</li>
                </ul>
		            <br />
					      <h3>Non-refundable subscriptions:</h3>
                  <ul>
                      <li>Monthly subscriptions after 7 days of service.</li>
                      <li>Subscriptions used excessively or abusively</li>
                  </ul>
		          	<br/>
                <h3>How to Request a Refund</h3>
                <p>
                  To request a refund, please contact our customer support team at this email: <b>contact@live-show.app</b>. Provide the details of your 
                  subscription and a description of the issue. We will review each request on a case-by-case basis and strive to 
                  resolve the matter promptly. Refunds are processed through Stripe, our payment provider.</p>
                <br/>
                <h3>Contact Us</h3>
                <p>If you have any questions regarding our refund policy, please contact us at the following address: <b>contact@live-show.app</b></p>

				      </div>{/* /.blog-one__content */}
		          <br />
		        </div>{/* /.blog-one__single */}
		      </div>{/* /.col-lg-8 */}
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>

    )
  }
}

export default BlogDetails;
