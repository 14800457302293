import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import About from './section-components/about';
import FunfactV4 from './section-components/funfact';
import Cta from './section-components/cta-v10';
import Team from './section-components/team-v2';
import Testimonial from './section-components/testimonial-style-one';
import Brand from './section-components/brand-v4';
import AboutV6 from './section-components/about-v6';
import Footer from './global-components/footer-v4';
import { Helmet } from 'react-helmet';
import i18n from "../i18n"

const AboutPage = () => {
    return <div>
        <Helmet>
            <title>What is Live Show ?</title>
            <meta id="metaTitle" name="title" content="What is Live Show ?" />
            <meta name="description" content="Fresh information about Live Show !" />
            <meta name="author" content="Live Show Inc" />

            {/* Open Graph / Facebook */}
            <meta property="og:site_name" content="Download Live Show" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.live-show.app/" />
            <meta property="og:title" content="Live Show" />
            <meta property="og:description" content="Fresh information about Live Show !" />
            <meta property="og:image" content="%PUBLIC_URL%/assets/logo-full-light.png" />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />

            {/* Twitter */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://www.live-show.app/" />
            <meta property="twitter:title" content="What is Live Show ?" />
            <meta property="twitter:description" content="Fresh information about Live Show !" />
            <meta property="twitter:image" content="%PUBLIC_URL%/assets/logo-full-light.png" />

            <meta name="msapplication-TileColor" content="#00301d" />
            <meta name="msapplication-TileImage" content="%PUBLIC_URL%/assets/logo-full-light.png" />
            <meta name="theme-color" content="#00ff99" />
        </Helmet>
        <Navbar />
        <PageHeader headertitle={i18n.t("about_us")}  />
        <About />
        <Cta />
        <Testimonial />
        {/*<Brand />*/}
        <AboutV6 />
        <Footer />
    </div>
}

export default AboutPage

